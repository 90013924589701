.footer {
    width: 100%;
}

.footer-content-container {
    margin-right: 10%; /* Adjust margin as needed */
}

.footer-content {
    width: 100%;
    color: black;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: linear-gradient(to right, #7e4eff, #ff4185);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1vh;
    white-space: nowrap;
}

.footer-logo {
    max-width: 200px;
}

.footer-form {
    display: flex;
    flex-direction: column;
}

.footer-form input,
.footer-form textarea {
    background-color: #eceff1;
    width: 50%;
    margin: 0 auto;
    box-sizing: border-box;
}

.footer-form button {
    background-color: #039be5;
    color: white;
    border: none;
    cursor: pointer;
    width: 50%;
    box-sizing: border-box;
}

@media (max-width: 1250px) {
    .footer-content {
        width: 100%;
        background-color: #fff;
        justify-content: center;
    }
    .footer-content-container {
        margin-right: 0; /* Adjust margin as needed */
        justify-content: center;

    }
}

@media (max-width: 970px) {
    .footer-content {
        width: 100%;
        background-color: #fff;
        justify-content: center;

    }

    .footer-content-container {
        margin-right: 0; /* Adjust margin as needed */
        justify-content: center;

    }
}

@media (max-width: 645px) {
    .footer-content {
        width: 100%;
        background-color: #fff;
    }
}
