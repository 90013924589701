.development{
    width: 100%;
    margin-top: 10%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
}

.text-outline {
    position: absolute;
    top: 0;
    font-size: 10vw;
    color: transparent;
    -webkit-text-stroke: 2px #343434;
    text-align: center;
    font-family: 'Optika', sans-serif;
    cursor: default;
}

.development-header{
    font-size: 10vw;
    color: white;
}

.development-span-div{
    width:50%;
    text-align: justify;
    margin-top:2%;
    margin-bottom:2%;
}

.development-span{
    display: inline-block;
    color: white;
    font-size: 1vw;
    text-align: justify;
}

.our-projects-span{
    font-size: 1vw;
}

.our-projects-button {
    background: url('../assets/ourprojects.png');
    background-size: cover;
    background-position: center;
    color: white;
    border: none;
    padding: 2% 5% 2% 5%;
    cursor: pointer;
}

.our-projects-button:hover {
    background: url('../assets/ourprojects-hover.png');
    background-size: cover;
    background-position: center;
}



@media (max-width: 970px) {

    .text-outline {
        -webkit-text-stroke: 1px #343434;
    }

    .development-span{
        width: 100%;
        display: inline-block;
        text-align: justify;
        color: white;
        font-size: 3vw;
    }

    .development-span-div{
        width:80%;
        text-align: justify;
        margin-top:2%;
        margin-bottom:2%;
    }

    .development-image{
        position:absolute;
        margin-top:-50% !important;
        width:70%;
        margin-left:10%;
    }
}