.mobile-menu-container {
    position: relative;
    background: black;
    display: flex;
}


.menu-icon {
    cursor: pointer;
}

.no-scroll {
    overflow: hidden;
}

.bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 4px 0;
}

.menu-options {
    display: none;
    padding: 0;
    margin: 0;
}

.menu-options.active {
    display: block;
    background: black;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left:0;
    z-index: 1;
}

.menu-options li {
    list-style-type: none;
    padding: 2rem;
}

.menu-options li a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    text-decoration: none;
    padding: 0.5rem;
    font-size: 2.5vh;
}

.menu-options li a:hover {
    background-color: #555;
}

/* CSS for X icon */
.menu-options .close-icon {
    display: none;
}

.menu-options.active .close-icon {
    display: flex;
    background: transparent;
    border: none;

}

.menu-options.active .close-icon:before {
    content: 'X';
    color: white;
    font-size: 3vh;
}

/* Animation */
@keyframes slideIn {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

/* Animation */
@keyframes slideOut {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-100%);
    }
}

/* Apply animation to the menu */
.menu-options {
    animation: slideIn 0.5s ease-in-out;;
}

.menu-options.slide-out {
    animation: slideOut 0.5s ease-in-out;
}

.top-right-buttons {
    display: flex;
    justify-content: flex-end;
    padding-right: 0.5rem;
    gap: 2rem;
}

@media (max-width: 550px) {
    .language-switcher-buttons {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
    }
}