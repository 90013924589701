.language-switcher {
    display: flex;
    gap: 1rem;
    position: absolute;
    top: 1.5rem;
    right: 5rem;
    align-items: center;
    z-index: 1;
}

.language-button {
    background: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
    transition: transform 0.2s ease, opacity 0.2s ease;
    opacity: 0.7;
}

.language-button .fi {
    font-size: 1.5rem;
}

.language-button.active {
    transform: scale(1.2);
    opacity: 1;
}

.language-button:hover {
    transform: scale(1.1);
    opacity: 1;
}

@media (max-width: 1300px) {
    .language-switcher {
        right: 2rem;
    }
}

@media (max-width: 970px) {
    .language-switcher {
        position: relative;
        top: unset;
        right: unset;
    }
}