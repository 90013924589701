.contact-form {
    width: 70%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

.disabled-field {
  background-color: rgba(25,20,20,0.8) !important;
  cursor: not-allowed !important;
  color: #a0a0a0 !important;
}

.contact-form-fade {
  width: 100% !important;
}

  .contact-form-header {
    font-size: 6vw;
    color: white;
    text-align: center;
    display: block;
    flex-direction: row;
  }

  .contact-form-content{
    width: 100%;
    text-align: justify;
    margin-top: 2%;
    margin-bottom: 2%;
    color: white;
    font-size: 1vw;
    line-height: 1.5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .contact-form-description {
    display: inline-block;
    text-align: center;
    width: 100%;
    color: white;
    font-size: 0.9vw;
  }

.contact-form-decorative-bar {
  display: flex;
  justify-content: center; /* Center horizontally */
  width: 30%;
  overflow: hidden; /* Hide the overflowing content */
}

.contact-form-decorative-bar > .DecorativeBar {
  width: 300%; /* Expand the DecorativeBar to three times its size */
  margin-left: -100%; /* Shift it left by 100% of its own width */
}

  .contact-form-hint-span {
    width: 50%;
  }

.contact-form input[type="text"] {
  margin-top: 2%;
  width: 100%;
  background-color: rgba(0,0,0,0.8);
  border-radius: 50vh;
  border: 1px solid white;
  color: white;
  text-align: left;
  vertical-align: center;
  padding: 4%;
}

.contact-form input[type="text"]::placeholder {
  align-items: flex-start;
  background: linear-gradient(to right, #7e4eff, #ff4185);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 2%;
  font-size: 2vh;
  width: fit-content;
}

.contact-form-button {
  margin-top: 5%;
  width: 100%;
  background: url('../assets/contact form button.png');
  background-size: cover;
  background-position: center;
  color: white;
  border: none;
  padding: 6% 15% 6% 15%;
  cursor: pointer;
}

.send-message-button {
  width: 100%;
  position: relative;
}


.contact-form textarea {
  width: 100%;
  margin-top: 2%;
  background-color: rgba(0,0,0,0.8);
  border-radius: 50px;
  border: 1px solid white;
  color: white;
  text-align: left;
  vertical-align: center;
  padding: 4%;
  resize: none;
  min-height: 100px; /* Minimum height of the textarea */
}



.contact-form textarea::placeholder {
  align-items: flex-start;
  background: linear-gradient(to right, #7e4eff, #ff4185);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 2%;
  font-size: 2vh;
  width: fit-content;
}

.contact-form textarea::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5); /* Thumb color */
  border-radius: 10px; /* Thumb border radius */
  height: 30px;
}

.contact-form textarea::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.contact-form textarea::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2); /* Track color */
}


@media (max-width: 970px) {
  .contact-form {
    width: 90%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .contact-form-button{
    width: 100%;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .contact-form textarea {
    width: 100%;
    background-color: rgba(0,0,0,0.8);
    border-radius: calc(10% + 20px);
    border: 1px solid white;
    color: white;
    text-align: left;
    vertical-align: center;
    padding: 5%;
    resize: none;
    min-height: 100px; /* Minimum height of the textarea */
  }
}