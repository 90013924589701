.tabs {
    display: flex;
    width: 80vw; /* Set a max width */
    flex-direction: column;
    align-items: center; /* Center the tabs horizontally */
    margin: 0 auto;
}

.tab-width {
    width: 90%;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.tab-width.entering {
    opacity: 1;
}

.tab-width.exiting {
    opacity: 0;
}

.tab-image {
    margin-top: 10%;
    transition: filter 1.5s ease, opacity 1.5s ease, transform 1.5s ease;
}

@keyframes infinite-rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.tab1 {
    filter: hue-rotate(-90deg);
}

.tab2 {
    filter: hue-rotate(0deg);
}

.tab3 {
    filter: hue-rotate(40deg);
}

.tab-item-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
}

.tab-item-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}

.tab-item-description {
    font-size: 14px;
    color: #666;
}


.tab-buttons {
    display: flex;
    width: 70%;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 20px;
    text-align: center;
}

.tab-buttons button {
    border: none;
    padding: 10px 20px;
    background-color: transparent;
    color: #888; /* Text color for non-selected tabs */
    cursor: pointer;
    font-size: 3vh;
}

.tab-buttons button.active, .mobile-tab-title {
    background: linear-gradient(to right, #7e4eff, #ff4185);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.mobile-tab-title {
    font-size: 3vh;
}

.tab {
    flex: 1;
}

.tab-decorative-bar {
    height: 2px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab-content {
    position: relative;
    color: white;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-top: 0.5rem;
    width: 100%;

}

.tab-content .side-container:last-child {
    grid-column: span 2;
    justify-self: center;
    width: 100%;
    border-right: none;
    box-sizing: border-box;
}

.tab-content .side-container:nth-child(odd) {
    border-left: none;
    border-right: none;
}

.tab-content .side-container:nth-child(even) {
    border-right: none;
}

.tab-list-text {
    font-size: 1.5vh;
}

.tab-list-title {
    font-size: 2.5vh;
}


.tab-item-title{
    background: linear-gradient(to right, #7e4eff, #ff4185);
    transition: filter 1.5s ease, opacity 1.5s ease, transform 1.5s ease;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3vh;
    overflow-wrap: anywhere;
}

.tab-item-icon{
    width: 100px !important;
    height: 100px !important;
    transition: filter 1.5s ease, opacity 1.5s ease, transform 1.5s ease;
}

.tab-title {
    transition: filter 1s ease, opacity 1s ease, transform 1s ease;
}

.swipe-indicator {
    display: block;
    margin-top: 8px;
    font-size: 1.6vh;
    color: rgba(255, 255, 255, 0.7);
    font-style: italic;
    animation: pulseFade 2s infinite alternate, jump 2s infinite ease-in-out;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.dots-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    gap: 0.5rem;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: #3f3f3f;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.active-dot {
    background-color: lightgray;
}

.mobile-background {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.25;
    z-index: -1;
    transition: filter 1.5s ease, opacity 1.5s ease, transform 1.5s ease;
}


@keyframes pulseFade {
    0% {
        opacity: 0.7;
    }
    100% {
        opacity: 1;
    }
}

@keyframes jump {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-2.5px);
    }
}


.content {
    animation: none;
}

.content.swipe-in {
    animation: swipeIn 0.35s ease-in;
}

.content.swipe-out {
    animation: swipeOut 0.35s ease-out;
}

@keyframes swipeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes swipeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}



@media (max-width: 1250px) {

}

@media (max-width: 970px) {
    .tabs {
        display: none;
        width: 100%;
    }

    .tab-buttons {
        display: flex;
        width: 100%;
        justify-content: space-between;
        background-color: rgba(0, 0, 0, 0.7); /* Black background with 20% opacity */
        border-radius: 20px; /* Rounded border */
    }

    .mobile-tabs {
        position: relative;
        overflow: hidden;
        padding: 0 1rem;
    }

    .side-container{
        text-align: center;

        padding: 1rem;
    }

    .tab-buttons button {
        border: none;
        width: 100%;
        background-color: transparent;
        color: #888; /* Text color for non-selected tabs */
        cursor: pointer;
        font-size: 3vh;
    }
}