.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    border: 1px solid white;
    background: black;
    text-align: justify;
    font-size: 1vw;
    padding: 20px;
    border-radius: 5px;
    width: 50%;
    color: white;
    max-height: 80vh;
    overflow: auto;
    position: relative;
}

.modal-content p{
    font-size: 1vw;
}

.close-button {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.modal-header span{
    font-size: 3rem;
}

.button-close {
    background: none;
    border-radius: 20%;
    border-color: white;
    border-inline-color: white;
    border-style: solid;
    color: white;
    padding: 8px 16px;
    cursor: pointer;
    box-shadow: none;
}

@media screen and (max-width: 960px) {
    .modal-content {
        text-align: justify;
        font-size: 4vw;
        padding: 20px;
        border-radius: 5px;
        width: 80%;
        color: white;
        max-height: 80vh;
        overflow: auto;
        position: relative;
    }

    .modal-content p{
        font-size: 3vw;
    }
}
