.navbar {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-text {
  color: white;
  padding-left: 40px;
  padding-right: 20px;
  font-size: 1vw;
}
  
.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 70%;
  padding: 0 24px;
  align-items: center; /* Add this line */
  flex-direction: row;
}

.navbar-divider {
  width: 100%;
  border: none;
  height: 1px; 
  background-color: rgba(255,255,255,0.2);
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
}

.navbar-logo img {
  width: 150% !important;
  height: auto;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  height: 100%;
  cursor: pointer;
  background-color: transparent; 
  border: transparent;
}

.navbar-logo img {
  max-width: 100px;
  height: auto;
  max-height: 50px;
  width: auto;
}

.nav-links:hover {
  border-bottom: 2px solid #fff;
}

.menu-icon {
  display: none;
}

.language-select{
  margin-left: 20px;
  color:white;
  background-color: rgba(0,0,0,0.3);
  border-radius: 20px;
  border-color: rgba(0,0,0,0);
  padding:5px;
  text-align: center;
  font-weight: bold;
}

@media screen and (max-width: 970px) {
  .navbar-divider {
    width: 50%;
    border: none;
    height: 1px;
    background-color: rgba(255,255,255,0.2);
  }

  .menu-icon {
    display: block;
    top: 0;
    right: 0;
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
    margin-right: 10%;
    margin-left: 10%;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: linear-gradient(to right, #3eabfe 0%, #a137fe 100%);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item {
    width: 100%;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    border: none;
  }
}

@media (max-width: 970px) {

  .navbar {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-start;
  }

  .navbar-text {
    color: white;
    padding-left: 40px;
    padding-right: 20px;
    font-size: 2vw;
  }


  .navbar-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 10%;
    z-index: 1;
    padding: 10px 0 0 0;
    align-items: center; /* Add this line */
  }

  .navbar-logo img {
    max-width: 50px;
    height: auto;
    max-height: 25px;
    width: auto;
  }
}