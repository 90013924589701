
.jobs{
    width:70%;
    margin-left: 15%;
    padding-top: 50%;
    padding-bottom: 20%;
}

.looking-for{
    color: white;
    padding-left: 5%;
    font-size: 2vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
}

.join-our-team {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.team-image {
    width: 35%;
}

.team-image img {
    width: 80%;
    height: auto;
    object-fit: cover;
    float: left;
}

.join-our-team-content {
    width: 65%;
}

.no-positions{
    font-size: 2vw;
    color: white;
    text-align: center;
}

.join-our-team-header {
    text-align: left;
    color: #fff;
    margin-right: 10%;
}

.position {
    margin-top: 20px;
    background-color: rgba(0,0,0,0.8);
    border-radius: 50px;
    padding: 20px;
    border: 1px solid white;
}

.position-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.arrow {
    width: 5% !important;
}

.arrow.down {
    transform: rotate(0);
}

.arrow.up {
    transform: rotate(180deg);
}

.position.active .position-description {
    display: block;
}

.water-image img {
    width: 100%;
    height: auto;
    display: block;
}

.position-name {
    background: linear-gradient(to right, #7e4eff, #ff4185);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 2%;
    font-size: large;
    font-size: 1.6rem;
}

.position-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: justify;
}

.content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.text-content {
    width: 50%;
    margin-left: 2%;
}

.image-content {
    width: 20%;
    margin-left: 10%;
}

.apply-button{
    color: white;
    font-size: 1.25rem;
    cursor: pointer;
    background: url('../assets/ourprojects.png');
    background-size: cover;
    background-position: center;
    color: white;
    border: none;
    padding: 2% 7% 2% 7%;
    cursor: pointer;
    border-color: 1px white;
}

.image-container {
    position: relative;
}

.crew-count{
    margin: auto;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.crew-count span{
    font-size: 1vw;
}

.crew-count-inner{
    background: url('../assets/icon number.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 30%;
    text-align: center;
    width: fit-content;
    position: relative;
    display: flex;
    flex-direction: row;
    font-size: 1vw;
}

.crew-count-inner-text{
    background: conic-gradient(#ff4185, #7e4eff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2vw;
    font-weight: bold;
    width: fit-content;
}


.job-header{
    display: flex;
    flex-direction: row;
    align-items: center;
}

@keyframes slide {
    from {
        opacity: 0;
        transform: translateX(-360px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

.jobs-animation {
    display: flex;
    flex-direction: column;
}

@media (max-width: 450px) {
    .no-positions {
        font-size: 3vw;
    }
}

@media (max-width: 970px) {
    .job-header{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .looking-for{
        margin-top: 5%;
        font-size: 0.9rem;
        text-align: center;
        margin-left: 0;
        padding-left: 0;
    }

    .text-content{
        font-size: 1rem;
        text-align: center; 
    }

    .position-name {
        font-size: 1rem;
    }

    .text-content p {
        font-size: 1rem;
    }
    .apply-button {
        font-size: 1rem;
    }

    .arrow {
        width: 15% !important;
    }

    .image-container {
        text-align: center;
    }

    .crew-count-inner-text {
        font-size: 1.2rem;
    }
}