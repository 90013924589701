.ar-vr-text{
    color: white;
    font-size: 1.5vh;
    text-align:justify;
    display: block;
}

.ar-vr-outter-div{
    width: 50%;
    margin-bottom: 2%;
    text-align: justify;
}

.vr-experience {
    position: absolute;
    top: 8.5%;
    right: 14%;
    height: 6%;
    width: 16%;
    text-align: center;
    align-content: center;
}

.ar-experience {
    position: absolute;
    top: 12%;
    left: 15.5%;
    text-align: center;
    height: 6%;
    width: 16%;
    align-content: center;
}

.mr-experience {
    position: absolute;
    top: 28%;
    left: 5%;
    height: 6%;
    width: 16%;
    text-align: center;
    align-content: center;
}

.bubbles-text {
    color: white;
    font-family: 'Optika', sans-serif;
}

.vr-text {
    font-size: 2vw;
    font-weight: bold;
}

.experience-text {
    font-size: 1.5vw;
}

.text-outline-arvr {
    position: absolute;
    top: 0;
    font-size: 10vw;
    transform: translateY(-5vw);
    color: transparent;
    -webkit-text-stroke: 2px #343434;
    text-align: center;
    font-family: 'Optika', sans-serif;
    cursor: default;
}

.arvr-header {
    font-size: 10vw;
    color: white;
    transform: translateY(-5vw);
}

.arvr-portal {
    mask-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,   /* Fully transparent at the top */
            rgba(0, 0, 0, 1) 20%,  /* Fully opaque starting from 20% */
            rgba(0, 0, 0, 1) 100%  /* Fully opaque at the bottom */
    );
    -webkit-mask-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 5%,
            rgba(0, 0, 0, 1) 100%
    );

    position: absolute;
    z-index: -1;
    width: 100vw;
}

.arvr-guy {
    max-width: 100% !important;
    max-height: 100% !important;
}

.arvr-guy-extras {
    position: absolute;
    max-width: 100% !important;
    max-height: 100% !important;
    transform-origin: center;
}

@media (max-width: 970px) {

    .ar-vr-outter-div{
        width: 80%;
        margin-bottom: 2%;
    }
}

@media (max-width: 600px) {
    .bubbles-text, .vr-experience, .ar-experience, .mr-experience {
        line-height: 1;
    }
}

@media (max-width: 450px) {
    .bubbles-text, .vr-experience, .ar-experience, .mr-experience {
        line-height: 0.6;
    }

    .vr-experience {
        top: 7.5%;
    }

    .ar-experience {
        top: 11%;
    }

    .mr-experience {
        top: 27%;
    }
}
