.about {
    width: 70%;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }

  .about-header {
    font-size: 6vw;
    color: white;
    margin-right: 2%;
    display: block;
  }

  .about-content {
    width: 100%;
    text-align: justify;
    margin-top: 2%;
    margin-bottom: 2%;
    color: white;
    font-size: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transform-style: preserve-3d;
  }

  .earth {
    position: relative;
    width: 25vw;
    height: 25vw;
    max-width: 18vw;
    max-height: 18vw;
    background: url("/src/assets/earth.png");
    background-size: cover;
    border-radius: 50%;
    box-shadow: inset 0 0 10px rgba(0,0,0,1), 0 0 30px #ed16fc;
    margin-top: -10%;
  }

  .earth-pin {
    position: absolute;
    width: 2.5rem !important;
    right: 40%;
    top: 40%;
    filter: drop-shadow(0 0 3px #ff0482);
    animation: jump 1s infinite ease-in-out;
    z-index: 1;
  }

  .earth-greetings {
    position: absolute;
    width: 40vw !important;
    right: 35%;
    bottom: 55%
  }

.about-decorative-bar {
  position: absolute;
  bottom: -10%;
  left: 40%;
  width: 100%;
}


.about-hint-span {
    width: 50%;
  }

  .globe-image {
    width: 50% !important;
    height: auto;
    margin-top: -10%;
  }


@keyframes jump {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}

@media (max-width: 970px) {
  .about {
    width: 100%;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }

  .about-header {
    font-size: 12vw;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .about-content {
    width: 100%;
    text-align: justify;
    margin-top: 2%;
    margin-bottom: 2%;
    color: white;
    font-size: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about-hint-span {
    width: 80%;
  }

  .globe-image {
    width: 50% !important;
    height: auto;
    margin-top: 5%;
  }

  .earth {
    margin-top: 5%;
    width: 50vw;
    height: 50vw;
    max-width: none;
    max-height: none;
  }

  .earth-pin {
    width: 5vw !important;
    height: auto !important;
  }

  .about-decorative-bar-title{
    display: flex;
    justify-content: center;
  }
}