html, body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Optika';
  src: local('Optika'), url('./fonts/Optika-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Optika';
  src: local('Optika Bold'), url('./fonts/Optika-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Optika', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Optika', sans-serif;
  font-weight: 700;
}

