.contacts {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 10%;
  }

  .contacts-header {
    font-size: 6vw;
    color: white;
    text-align: center;
    display: block;
    flex-direction: row;
  }

  .contacts-content{
    width: 100%;
    text-align: justify;
    margin-top: 2%;
    margin-bottom: 2%;
    color: white;
    font-size: 3vw;
    line-height: 1.5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .contacts-description {
    display: inline-block;
    text-align: center;
    width: 100%;
    color: white;
    font-size: 3vw;
  }

.contacts-decorative-bar {
  display: flex;
  justify-content: center; /* Center horizontally */
  width: 30%;
  overflow: hidden; /* Hide the overflowing content */
}

.contacts-decorative-bar > .DecorativeBar {
  width: 300%; /* Expand the DecorativeBar to three times its size */
  margin-left: -100%; /* Shift it left by 100% of its own width */
}

.contacts-image {
    width: 50px !important;
    cursor: pointer;
}

@media (max-width: 600px) {
    .contacts-image {
        width: 25px !important;
    }
}