.main-content{
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    position: relative;
}

.menu{
    display:flex;
    width:auto;
    margin-left:15%;
}

@media (max-width: 1250px) {
    .menu {

    }
}

@media (max-width: 970px) {
    .menu {
        display: none;
    }

    .language_selector {
        display: none;
    }
}

@media (max-width: 645px) {
    .menu {

    }
}

.first-section{
    overflow: hidden;
    position: relative;
    padding-bottom: 1vw;
}

.image-wrapper {
    position: absolute;
    width: 100%;
    height: auto;
}

.background-image {
    width: 100% !important;
    object-fit: contain;
    height: auto !important;
    display: block;
    max-height: none !important;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%);
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%);
}

.development-bg {
    mask-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 20%,
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 0) 100%
    );
    -webkit-mask-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 20%,
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 0) 100%
    );
    width: 50% !important;
    height: 100% !important;
    z-index: 0;
    object-fit: cover;
    max-height: none !important;
}

.development-bg-wrapper {
    position: absolute;
    width: 100%;
}

.development-bg-container {
    width: 200%;
    height: 100%;
}

.main-title {
    margin-top: 10%;
    margin-bottom: 10%;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: auto;
    font-family: 'Optika';
}

.title-span {
    font-size: 8vw;
    color: white;
    text-align: left;
    display: block;
    width: 70%;
    font-weight: bold;
}

.title-hint{
    font-size: 3vw;
    color: white;
    text-align: left;
    display: block;
    width: 70%;
}

.main-content img{
    max-width: 100%;
    height: auto;
    max-height: 800px;
    width: auto;
}

.main-content .container {
    position: relative;
}

.main-content .container > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.main-content p{
    color: #fff;
    font-size: 24px;
}

.main-content .talk-to-us {
    margin-right: 20%;
}


.down-button{
    background-color: transparent;
    border: transparent;
    cursor: pointer;
}

.arvr{
    width: 100%;
    margin-top: 40%;
    position: relative;
}

.mid-section{
    background-image: url("/src/assets/spaceship.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    top: 0;
    position: relative;
}

.final-section{
    background-image: url("/src/assets/tunel.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    top: 0;
    position: relative;
}